<template>
    <v-container
            id="dashboard"
            fluid
            tag="section"
    >
        <v-row>
            <v-col
                    cols="12"
                    lg="6"
            >
                <base-material-chart-card
                        :data="emailsSubscriptionChart.data"
                        :options="emailsSubscriptionChart.options"
                        :responsive-options="emailsSubscriptionChart.responsiveOptions"
                        color="#E91E63"
                        hover-reveal
                        type="Bar"
                >
                    <template v-slot:reveal-actions>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                        v-bind="attrs"
                                        color="info"
                                        icon
                                        v-on="on"
                                >
                                    <v-icon
                                            color="info"
                                    >
                                        mdi-refresh
                                    </v-icon>
                                </v-btn>
                            </template>

                            <span>Refresh</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                        v-bind="attrs"
                                        light
                                        icon
                                        v-on="on"
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>

                            <span>Change Date</span>
                        </v-tooltip>
                    </template>

                    <h4 class="card-title font-weight-light mt-2 ml-2">
                        Biaya Pengadaan
                    </h4>

                    <p class="d-inline-flex font-weight-light ml-2 mt-1">
                        per Bulan (4 bulan sebelumnya)
                    </p>

                    <template v-slot:actions>
                        <v-icon
                                class="mr-1"
                                small
                        >
                            mdi-clock-outline
                        </v-icon>
                        <span class="caption grey--text font-weight-light">updated 10 minutes ago</span>
                    </template>
                </base-material-chart-card>
            </v-col>

            <v-col
                    cols="12"
                    lg="6"
            >
                <base-material-chart-card
                        :data="dailySalesChart.data"
                        :options="dailySalesChart.options"
                        color="success"
                        hover-reveal
                        type="Bar"
                >
                    <template v-slot:reveal-actions>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                        v-bind="attrs"
                                        color="info"
                                        icon
                                        v-on="on"
                                >
                                    <v-icon
                                            color="info"
                                    >
                                        mdi-refresh
                                    </v-icon>
                                </v-btn>
                            </template>

                            <span>Refresh</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                        v-bind="attrs"
                                        light
                                        icon
                                        v-on="on"
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>

                            <span>Change Date</span>
                        </v-tooltip>
                    </template>

                    <h4 class="card-title font-weight-light mt-2 ml-2">
                        Pendapatan Penjualan
                    </h4>

                    <p class="d-inline-flex font-weight-light ml-2 mt-1">
                        <!--<v-icon-->
                                <!--color="green"-->
                                <!--small-->
                        <!--&gt;-->
                            <!--mdi-arrow-up-->
                        <!--</v-icon>-->
                        <!--<span class="green&#45;&#45;text">55%</span>&nbsp;-->
                        <!--increase in today's sales-->
                        per Bulan (4 bulan sebelumnya)
                    </p>

                    <template v-slot:actions>
                        <v-icon
                                class="mr-1"
                                small
                        >
                            mdi-clock-outline
                        </v-icon>
                        <span class="caption grey--text font-weight-light">updated 4 minutes ago</span>
                    </template>
                </base-material-chart-card>
            </v-col>

            <!--<v-col-->
                    <!--cols="12"-->
                    <!--lg="4"-->
            <!--&gt;-->
                <!--<base-material-chart-card-->
                        <!--:data="dataCompletedTasksChart.data"-->
                        <!--:options="dataCompletedTasksChart.options"-->
                        <!--hover-reveal-->
                        <!--color="info"-->
                        <!--type="Line"-->
                <!--&gt;-->
                    <!--<template v-slot:reveal-actions>-->
                        <!--<v-tooltip bottom>-->
                            <!--<template v-slot:activator="{ attrs, on }">-->
                                <!--<v-btn-->
                                        <!--v-bind="attrs"-->
                                        <!--color="info"-->
                                        <!--icon-->
                                        <!--v-on="on"-->
                                <!--&gt;-->
                                    <!--<v-icon-->
                                            <!--color="info"-->
                                    <!--&gt;-->
                                        <!--mdi-refresh-->
                                    <!--</v-icon>-->
                                <!--</v-btn>-->
                            <!--</template>-->

                            <!--<span>Refresh</span>-->
                        <!--</v-tooltip>-->

                        <!--<v-tooltip bottom>-->
                            <!--<template v-slot:activator="{ attrs, on }">-->
                                <!--<v-btn-->
                                        <!--v-bind="attrs"-->
                                        <!--light-->
                                        <!--icon-->
                                        <!--v-on="on"-->
                                <!--&gt;-->
                                    <!--<v-icon>mdi-pencil</v-icon>-->
                                <!--</v-btn>-->
                            <!--</template>-->

                            <!--<span>Change Date</span>-->
                        <!--</v-tooltip>-->
                    <!--</template>-->

                    <!--<h3 class="card-title font-weight-light mt-2 ml-2">-->
                        <!--Completed Tasks-->
                    <!--</h3>-->

                    <!--<p class="d-inline-flex font-weight-light ml-2 mt-1">-->
                        <!--Last Last Campaign Performance-->
                    <!--</p>-->

                    <!--<template v-slot:actions>-->
                        <!--<v-icon-->
                                <!--class="mr-1"-->
                                <!--small-->
                        <!--&gt;-->
                            <!--mdi-clock-outline-->
                        <!--</v-icon>-->
                        <!--<span class="caption grey&#45;&#45;text font-weight-light">campaign sent 26 minutes ago</span>-->
                    <!--</template>-->
                <!--</base-material-chart-card>-->
            <!--</v-col>-->

            <v-col
                    cols="12"
                    sm="6"
                    lg="3"
            >
                <base-material-stats-card
                        color="info"
                        icon="mdi-archive"
                        title="Jumlah Pengadaan"
                        :value="statsJmlPengadaan"
                        sub-icon="mdi-clock"
                        :sub-text="statsJmlPengadaanText"
                />
            </v-col>

            <v-col
                    cols="12"
                    sm="6"
                    lg="3"
            >
                <base-material-stats-card
                        color="primary"
                        icon="mdi-store"
                        title="Jumlah Penjualan"
                        :value="statsJmlPenjualan"
                        sub-icon="mdi-tag"
                        :sub-text="statsJmlPenjualanText"
                />
            </v-col>

            <v-col
                    cols="12"
                    sm="6"
                    lg="3"
            >
                <base-material-stats-card
                        color="success"
                        icon="mdi-currency-usd-off"
                        title="Belum Lunas"
                        :value="statsJmlPenjualanBlmLunas"
                        sub-icon="mdi-calendar"
                        :sub-text="statsJmlPenjualanBlmLunasText"
                />
            </v-col>

            <v-col
                    cols="12"
                    sm="6"
                    lg="3"
            >
                <base-material-stats-card
                        color="orange"
                        icon="mdi-cash-usd"
                        title="Total Pendapatan"
                        :value="statsTotPenjualan"
                        sub-icon="mdi-alert"
                        sub-icon-color="red"
                        :sub-text="statsTotPenjualanText"
                />
            </v-col>

            <v-col
                    cols="12"
                    md="6"
            >
                <base-material-card
                        color="warning"
                        class="px-5 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Daftar Pelanggan
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            dengan pembelian terbanyak dari 7 bulan sebelumnya
                        </div>
                    </template>
                    <v-card-text>
                        <v-data-table
                                :headers="headers"
                                :items="items"
                        >
                            <template v-slot:item.tot="{ item }">
                                <input type="hidden" v-model.lazy="item.tot" v-money="money" /> {{item.tot}}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </base-material-card>
            </v-col>
            <v-col
                    cols="12"
                    md="6"
            >
                <base-material-card
                        color="error"
                        class="px-5 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Daftar Produk
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            dengan penjualan terbanyak dari 7 bulan sebelumnya
                        </div>
                    </template>
                    <v-card-text>
                        <v-data-table
                                :headers="headersProduct"
                                :items="itemsProduct"
                        >
                            <template v-slot:item.tot="{ item }">
                                <input type="hidden" v-model.lazy="item.tot" v-money="money" /> {{item.tot}}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </base-material-card>
            </v-col>

        </v-row>
        <v-dialog v-model="dialog" persistent max-width="290">
            <v-card>
                <v-card-text class="text-center">
                    Loading
                    <v-spacer></v-spacer>
                    <v-progress-linear
                            indeterminate
                            rounded
                            color="yellow darken-2"
                    ></v-progress-linear>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import {ApiUrl} from '../../config/constant'
    import axios from 'axios'
    import {
        mapState,
    } from 'vuex'
    import {VMoney} from 'v-money'
    export default {
        name: 'DashboardDashboard',

        data() {
            return {
                dialog: false,
                statsBulanIni: '',
                statsJmlPengadaan: 0,
                statsJmlPengadaanText: '',
                statsJmlPenjualan: 0,
                statsJmlPenjualanText: '',
                statsTotPenjualan: 0,
                statsTotPenjualanText: '',
                statsJmlPenjualanBlmLunas: 0,
                statsJmlPenjualanBlmLunasText: '',
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'Rp ',
                    suffix: '',
                    precision: 0,
                    masked: false /* doesn't work with directive */
                },
                dailySalesChart: {
                    data: {
                        labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
                        series: [
                            [12, 17, 7, 17, 23, 18, 38],
                        ],
                    },
                    options: {
                        lineSmooth: this.$chartist.Interpolation.cardinal({
                            tension: 0,
                        }),
                        low: 0,
//                        high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                        chartPadding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 20,
                        },
                    },
                },
                dataCompletedTasksChart: {
                    data: {
                        labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
                        series: [
                            [230, 750, 450, 300, 280, 240, 200, 190],
                        ],
                    },
                    options: {
                        lineSmooth: this.$chartist.Interpolation.cardinal({
                            tension: 0,
                        }),
                        low: 0,
                        high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                        chartPadding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                        },
                    },
                },
                emailsSubscriptionChart: {
                    data: {
                        labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
//                        labels: this.dataBulan,
                        series: [
                            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
//                            this.seriesPengadaan
                        ],
                    },
                    options: {
                        axisX: {
                            showGrid: false,
                        },
//                        axisY: {
//                            onlyInteger: true
//                        },
//                        fullWidth: true,
                        low: 0,
//                        high: 1000,
                        chartPadding: {
                            top: 0,
                            right: 5,
                            bottom: 0,
                            left: 20,
                        },
                    },
                    responsiveOptions: [
                        ['screen and (max-width: 640px)', {
                            seriesBarDistance: 5,
                            axisX: {
                                labelInterpolationFnc: function (value) {
                                    return value[0]
                                },
                            },
                        }],
                    ],
                },
                headers: [
                    {
                        sortable: false,
                        text: 'Nama',
                        value: 'name',
                    },
                    {
                        sortable: false,
                        text: 'Alamat',
                        value: 'address',
                    },
                    {
                        sortable: false,
                        text: 'Jumlah Order',
                        value: 'jml',
                        align: 'right',
                    },
                    {
                        sortable: false,
                        text: 'Total Pembelian',
                        value: 'tot',
                        align: 'right',
                    },
                ],
                items: [],
                headersProduct: [
                    {
                        sortable: false,
                        text: 'Nama',
                        value: 'name',
                    },
                    {
                        sortable: false,
                        text: 'Jumlah Order',
                        value: 'jml',
                        align: 'right',
                    },
                    {
                        sortable: false,
                        text: 'Total Pembelian',
                        value: 'tot',
                        align: 'right',
                    },
                ],
                itemsProduct: [],
                tabs: 0,
                tasks: {
                    0: [
                        {
                            text: 'Sign contract for "What are conference organizers afraid of?"',
                            value: true,
                        },
                        {
                            text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
                            value: false,
                        },
                        {
                            text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
                            value: false,
                        },
                        {
                            text: 'Create 4 Invisible User Experiences you Never Knew About',
                            value: true,
                        },
                    ],
                    1: [
                        {
                            text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
                            value: true,
                        },
                        {
                            text: 'Sign contract for "What are conference organizers afraid of?"',
                            value: false,
                        },
                    ],
                    2: [
                        {
                            text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
                            value: false,
                        },
                        {
                            text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
                            value: true,
                        },
                        {
                            text: 'Sign contract for "What are conference organizers afraid of?"',
                            value: true,
                        },
                    ],
                },
                list: {
                    0: false,
                    1: false,
                    2: false,
                },
            }
        },
        computed: {
            ...mapState(['currentUser']),
        },
        mounted: function () {
            this.ambilData()
        },
        methods: {
            complete(index) {
                this.list[index] = !this.list[index]
            },
            ambilData: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                axios
                ({method: 'get', url: ApiUrl + 'dashboardweb', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        if (response.data.success) {
//                            this.items = response.data.data
                            this.emailsSubscriptionChart.data.labels = response.data.data_bln
                            this.emailsSubscriptionChart.data.series = [response.data.dataPengadaan]
                            this.dailySalesChart.data.labels = response.data.data_bln
                            this.dailySalesChart.data.series = [response.data.dataPenjualan]
                            this.statsBulan = response.data.bulanIni
                            this.statsJmlPenjualan = ''+response.data.dataJmlPenjualan
                            this.statsJmlPenjualanText = 'Bulan '+this.statsBulan
                            this.statsJmlPengadaan = ''+response.data.dataJmlPengadaan
                            this.statsJmlPengadaanText = 'Bulan '+this.statsBulan
                            this.statsTotPenjualan = ''+response.data.dataTotPenjualan
                            this.statsTotPenJualanText = 'Bulan '+this.statsBulan
                            this.statsJmlPenjualanBlmLunas = ''+response.data.dataJmlPenjualanBlmLunas
                            this.statsJmlPenjualanBlmLunasText = 'Bulan '+this.statsBulan
                            this.items = response.data.dataListCustomer
                            this.itemsProduct = response.data.dataListProduct
                            console.log(this.emailsSubscriptionChart)
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
        },
    }
</script>
